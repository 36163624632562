import React from 'react'
import Button from '../../main/components/buttons/Button'
import Logo from '../../../assets/images/logo.svg'
import { Link } from "react-router-dom";
import Purchase from '../../main/purchase/purchase';
import { IoCartOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";


const Header = ({ count = 0, showCart, onPurchase }) => {
    return (
        <div className='flex justify-between items-center bg-black px-6 py-2'>
            <div>
                <Link to="/">
                    <img className="w-[60px] h-[60px]" src={Logo} alt="logo-dark" />
                </Link>
            </div>
            <div className='flex gap-3'>
                {count > 0 && (
                    <>
                        {showCart && <Button icon_position='right' icon={<IoCartOutline className='text-[18px] mr-2' />} name={"Purchase"} className="btn btn-secondary" onClick={onPurchase} />}
                        <Button icon_position='right' icon={<FiDownload className='text-[18px] mr-2' />} name={"Download All"} className="btn btn-secondary" />
                    </>
                )}
            </div>
        </div>
    )
}

export default Header
