import React from 'react';
import Thumb from "../../../../assets/images/creator.jpeg"
import DefaultDropdown from "../../components/buttons/defalutDropdown";
import { Link } from 'react-router-dom';
import { MdPaid } from "react-icons/md";
import { AiFillPoundCircle } from "react-icons/ai";

const data = [
    { name: "Folder 1" },
    { name: "Folder 2" },
    { name: "Folder 3" },
    { name: "Folder 4" },
]

const Folders = ({ item, deleteFile, openShareModal, openEditModal }) => {
    const content = [
        {
            label: 'Share',
            onClick: openShareModal
        },
        {
            label: 'Edit',
            onClick: openEditModal
        },
        {
            label: 'Delete',
            onClick: deleteFile
        },
    ]
    return (
        <div className={"bg-theme-5 rounded-md flex justify-between items-center text-white text-[14px]"}>
            <Link to={`/cloud/drive/${item.id}`} className='w-full'>
                <span className={"flex items-center h-12 flex-1 cursor-pointer pl-4 gap-2"}>
                    <span className='w-10 h-10'>
                        {item.meta?.svg}
                    </span>
                    {item?.folder?.name || item.meta?.name}
                    {item.folder?.type == 1 ?
                        <AiFillPoundCircle className='text-[#FFD700] text-[16px]' /> : null}
                </span>
            </Link>
            {item.meta?.canDelete == 0 ? null :
                < button className={"flex flex-0 items-center h-10 px-4"}>
                    <DefaultDropdown data={item} content={content} />
                </button>}
        </div >
    );
};

export default Folders;
