import React from 'react'
const Login = (props) => {
    return (
        <div className={"bg-red-50"}>
            Login
        </div>
    );

}

export default Login;
