import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../components/pageHeading/PageHeading";
import PurchaseTable from "../components/table/purchaseTable";
import Search from "../components/search/Search";
import { getPurchasedContent } from "../../../service/service.content";
import { SUCCESS200, SUCCESS201 } from "../../../constants/constants";
import moment from "moment";
import NoThumb from '../../../assets/images/creator.jpeg'
import { UserContext } from "../../../context/user.context";
import EmptyPage from '../components/emptyPage/EmptyPage'
import MainLoader from "../components/loading/MainLoader";

const Purchase = ({ folderName = "test" }) => {
    const [searchText, setSearchText] = useState("");

    const handleSearchText = (value) => {
        setSearchText(value)
    }
    const [view, setView] = useState("grid");
    const [search, setSearch] = useState(false);
    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (folderName === "Search Files") {
            setSearch(true);
        }
    }, []);

    useEffect(() => {
        if (!user.isAuthenticated) {
            window.location.href = window.location.host.includes("localhost:")
                ? `http://localhost:3001/?redirect=http://localhost:3000`
                : `https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io`;
        } else {
            setIsLoading(true);
            loadContents();
        }
    }, []);

    const loadContents = async () => {
        await getPurchasedContent()
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200 &&
                    res.data.contents
                ) {
                    const newContent = res.data.contents.map((content) => {
                        return {
                            id: content.uuid,
                            slug: content.slug,
                            meta: {
                                name: content.caption || "No Caption",
                                image: content.thumbnail || NoThumb,
                                type: content.file_type,
                                time: moment(content.order_details.created_at).format("hh:mm A"),
                                date: moment(content.order_details.created_at).format("MM/DD/YYYY"),
                                price: "£" + content.order_details.price,
                                rejectedReason: content.rejectedReason,
                                isRejected: content.isRejected,
                                deleted_at: content.deleted_at,
                                status: content.status,
                                user: content.user
                            },
                        };
                    });

                    setContents([...newContent]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const toggleSearch = () => {
        setSearch(!search);
    };

    return (
        <div>
            <PageHeading
                heading={"Purchase"}
                profile
            />
            <Search
                folderName={"Search Files"}
                searchText={searchText}
                handleSearchText={handleSearchText}
            />
            {isLoading ?
                <MainLoader />
                :
                <div className={"p-4"}>
                    {contents && contents.length > 0 ?
                        <PurchaseTable contents={contents} /> :
                        <EmptyPage />}
                </div>}
        </div>
    )
}
export default Purchase;
