import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import DefaultInput from '../inputs/DefaultInput'
import TextareaInput from '../inputs/TextareaInput'
import SwitchInput from '../inputs/SwitchInput'
import { SUCCESS200, SUCCESS201 } from '../../../../constants/constants'
import { getContentById, updateContent } from '../../../../service/service.content'
import toast from '../../../../utils/toast'
import TagInput from '../inputs/TagInput'
import MainLoader from '../loading/MainLoader'

const EditModal = ({ isOpen, closeModal, fileId, loadFreshContent }) => {
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        // if (!fileId) return
        if (fileId) {
            setIsLoading(true);
            getContentById(fileId)
                .then((res) => setContent(res.data.content))
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    }, [fileId]);

    const handleUpdate = (name, value) => {
        setContent({ ...content, [name]: value })
    }

    const onSubmit = () => {
        setSaving(true);
        updateContent(content.uuid, content)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content was updated successfully!");
                    closeModal(true)
                    loadFreshContent(fileId)
                } else {
                    toast.error("Unable to update Content");
                }
            })
            .finally(() => setSaving(false));
    };

    if (isLoading) return <MainLoader />;

    // if (!content) return <EmptyPage content="No Content Found" />;
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => closeModal()} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-3 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white mb-5"
                                >
                                    Update File
                                </Dialog.Title>

                                <div className={"flex flex-col gap-2"}>
                                    <DefaultInput
                                        placeholder={"Enter Caption"}
                                        type={"text"}
                                        bg="bg-theme-5"
                                        value={content?.caption}
                                        name="caption"
                                        onChange={handleUpdate}
                                    />
                                    <TextareaInput
                                        placeholder={"Description"}
                                        type={"text"}
                                        value={content?.description}
                                        bg="bg-theme-5"
                                        name="description"
                                        onChange={handleUpdate}
                                    />
                                    <SwitchInput
                                        label={(content?.is_paid) ? "Premium" : "Free"}
                                        bg="bg-theme-5"
                                        name="is_paid"
                                        checked={content?.is_paid}
                                        onChange={handleUpdate}
                                    />
                                    <div className='tag-list mb-3'>
                                        <TagInput
                                            tags={content?.tags || []}
                                            updateTags={(val) => handleUpdate("tags", val)} />
                                    </div>
                                </div>

                                <div className="mt-4 flex justify-end gap-2">
                                    <button
                                        disabled={saving}
                                        type="button"
                                        className="inline-flex justify-center rounded-md border-0 bg-theme-peach-200
                                        px-4 py-2 text-sm font-medium text-white hover:bg-theme-peach-200
                                        focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500
                                        focus-visible:ring-offset-2"
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            onSubmit()
                                        }}
                                    >
                                        {saving ? "Loading..." :
                                            "Update"}
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border-0 bg-white px-4 py-2
                                         text-sm font-medium text-theme-1 hover:bg-white/90 focus:outline-none f
                                         ocus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default EditModal
