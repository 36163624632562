import React, {useEffect, useState} from 'react';

const DataCards = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadedImages, setUploadedImages] = useState([]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    useEffect(() => {
        // Check if a new image is selected
        if (selectedImage) {
            // For now, let's just log the selected image file
            console.log(selectedImage);
            setUploadedImages([...uploadedImages, selectedImage]);
            setSelectedImage(null); // Reset selectedImage after upload
        }
    }, [selectedImage, uploadedImages]);


    return (
        <div className="grid grid-cols-2 gap-4">
            <div className={"grid gap-4"}>
                <div className={"bg-theme-3 h-[200px]"}></div>
                <div className={"bg-theme-3 h-[200px]"}></div>
            </div>
            <div className={"bg-theme-3 h-full"}></div>

        </div>
    );
};

export default DataCards;
