import React from "react";
import PageHeading from "../components/pageHeading/PageHeading";
const Contracts = ({}) => {
    return (
        <div>
            <PageHeading
                heading={"Contracts"}
                profile
            />
            <div className={"w-full h-[calc(100vh-100px)] flex justify-center items-center"}>
                <span className={"text-[40px] text-white font-medium"}>COMING SOON...</span>
            </div>
        </div>
    )
}
export default Contracts;
