import React, { useEffect, useState } from 'react'
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";

const UploadAccordion = ({ show = false, onClose = () => null, currentUploading }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [totalUplaod, setTotalUplaod] = useState(0)
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        return () => {
            setIsOpen(false);
        }
    }, [])

    useEffect(() => {
        if (!currentUploading.length) return
        calucaluteTotalUploading()
    }, [currentUploading])

    const calucaluteTotalUploading = () => {
        const uploadedFiles = currentUploading.filter((item) => item.preview || item.progress == 100)
        setTotalUplaod(uploadedFiles?.length)
    }

    return (show ?
        <div className="fixed z-[99] bottom-0 right-8 rounded-t-[15px] rounded-bl-none bg-[#F8FAFD] border-l border-gray-300 overflow-hidden w-[350px]">
            <div className="flex justify-between items-center p-4 cursor-pointer">
                <div className="flex items-center">
                    <span className="font-semibold">
                        {totalUplaod == currentUploading.length ?
                            `${currentUploading?.length} uploads complete` :
                            `Uploading ${currentUploading?.length - totalUplaod} items`
                        }
                    </span>
                </div>
                <div className="flex items-center">
                    <button
                        onClick={toggleAccordion}
                        className="text-gray-500 focus:outline-none ml-2"
                    >
                        {isOpen ? (
                            <IoIosArrowDropdown className="text-[25px]" />
                        ) : (
                            <IoIosArrowDropup className="text-[25px]" />
                        )}
                    </button>
                    <div className="ml-2 text-red-500">
                        <IoClose onClick={() => {
                            onClose()
                        }} className="text-[25px] cursor-pointer" />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={`transition-all !duration-500 ease-in-out ${isOpen ? 'max-h-[300px]' : 'max-h-0'} bg-white overflow-y-auto overflow-x-hidden custom-scrollbar`}>
                    {currentUploading.length && currentUploading.map((item, index) => {
                        return <FileCard file={item} index={index} />
                    })}

                </div>
            )}
        </div> : null
    )
}

const Spinner = ({ progess }) => {
    return <div class="relative h-5 w-5">
        <svg class="h-full w-full" width="100" height="100" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-gray-200 dark:text-gray-700" stroke-width="4"></circle>
            <g class="origin-center -rotate-90 transform">
                <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-blue-600 dark:text-blue-500" stroke-width="4" stroke-dasharray="100" stroke-dashoffset={progess || 100}></circle>
            </g>
        </svg>
    </div>
}

const FileCard = ({ file, index }) => {

    return (
        <div key={index} className='flex justify-between items-center p-3 hover:bg-[#EDEDED]'>
            <div title={file?.file?.name} className='text-[14px] truncate pr-2'>{file?.file?.name}</div>
            <div>
                {file.progress < 100 && !file.preview ? <Spinner progess={file.progress ? 100 - file?.progress : 100} /> :
                    <FaCircleCheck className='text-[18px] text-[green]' />}
            </div>
        </div>
    );
};

export default UploadAccordion
