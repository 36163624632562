import { Combobox, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { RiContractUpDownLine } from "react-icons/ri";

const SearchInput = ({ list, handleOnChange, loading, searchValue, selected, setSelected }) => {

    return (
        <div className="">
            <Combobox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            placeholder="Search and Add People"
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 bg-neutral text-[14px] text-gray-400 focus:ring-0 focus:outline-[0] "
                            displayValue={searchValue}
                            onChange={(event) => handleOnChange(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <RiContractUpDownLine
                                className="h-5 w-5 text-[10px] text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => handleOnChange('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {list.length === 0 && searchValue !== '' ? (
                                <div className="relative cursor-default select-none px-4 py-2 text-black">
                                    {loading ? "Searching..." : "Nothing found."}
                                </div>
                            ) : (
                                list.map((person) => (
                                    <Combobox.Option
                                        key={person?.id}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-3 pr-4 ${active ? 'bg-neutral text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person?.search ? person.email : person?.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center ${active ? 'text-white' : 'bg-neutral'
                                                            }`}
                                                    >
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}

export default SearchInput
