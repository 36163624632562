import { useState } from "react";
import { useRef } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

const UploadInput = ({ multiple = true, info = "", handleFileUpload, label = "" }) => {
    const fileRef = useRef();
    const [dragActive, setDragActive] = useState(false);

    const handleUpload = (target) => {
        if (fileRef.current && target.files.length > 0) {
            handleFileUpload(target.files);
            fileRef.current.value = '';
        }
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileUpload(e.dataTransfer.files);
        }
    };
    return (
        <div className={"w-full"}>
            {!multiple ?
                <input
                    className={`hidden`}
                    id={"upload"}
                    onChange={(e) => handleUpload(e.target)}
                    ref={fileRef}
                    type="file"
                    name="img"
                    accept={".png, .jpg"}
                /> : <input
                    className={`hidden`}
                    id={"upload"}
                    onChange={(e) => handleUpload(e.target)}
                    multiple
                    ref={fileRef}
                    type="file"
                    name="img"
                    accept={"video/* ,image/*"}
                />}
            <span className={"text-gray-200 text-[14px] font-medium"}>{label}</span>
            <label
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                htmlFor={"upload"}
                className={"text-gray-200 text-[30px] font-medium bg-red-100"}
            >
                <span className={"border border-blue-100 border-dashed w-full h-[150px] " +
                    " flex flex-col justify-center items-center rounded-md"}>
                    <IoCloudUploadOutline />
                    <h3 className={"text-[20px] font-medium"}>Drag & Drop</h3>
                    <p className={"text-[14px]"}>Your files here & Browse to upload</p>
                    <p className={"text-[10px] text-indigo-400"}>
                        {info ? info :
                            "Only JPEG, PNG, GIF and PDF files with max size of 100 MB."}
                    </p>
                </span>
            </label>
        </div>
    )
}
export default UploadInput;
