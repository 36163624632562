// components/Table.js
import React from 'react';
import TableDropdown from "./components/tableDropdown";

const DefaultTable = ({data}) => {
    const links = [
        {href: '/', label: 'Rename'},
        {href: '/', label: 'Share'},
        {href: '/', label: 'Delete'},
    ]
    return (
        <div className="">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                <tr className={"bg-theme-5 text-[14px]"}>
                    <th className="border-0 p-4 font-medium">Name</th>
                    <th className="border-0 p-4 font-medium">Shared By</th>
                    <th className="border-0 p-4 font-medium">Shared Date</th>
                    <th className="border-0 p-4 font-medium">Action</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => (
                    <tr key={row.id} className={'border-b border-theme-5'}>
                        <td className="border-0 p-4">
                            <div className={"flex flex-row items-center gap-2"}>
                                {row.icon}
                                {row.name}
                            </div>
                        </td>
                        <td className="border-0 px-4 py-2">{row.sharedBy}</td>
                        <td className="border-0 px-4 py-2">{row.sharedDate}</td>
                        <td className="border-0 px-4 py-2"><TableDropdown links={links}/></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DefaultTable;
