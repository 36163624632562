let API_BASE_URL = "https://api.creatorstock.io/api";
let IMAGE_BASE_URL = "https://cdn.creatorstock.io";
let API_URL = "http://127.0.0.1:8000/api";
let maxUpload = 1048576 * 10; // 10 mb
const LIMIT = 22;
const SUCCESS200 = 200;
const SUCCESS201 = 201;
const CURRENCY_SYMBOL = '£';
 
const TAGS_LIST = [
    {text: "Holiday 🌴", slug:"holiday"},
    {text: "Beach 🏖️", slug: "beach"},
    {text: "Nature 🌲", slug: "nature"},
    {text: "Food 🥑", slug: "food"},
    {text: "Social 👫", slug: "social"},
    {text: "Partying 💃", slug: "partying"},
    {text: "Travel ✈️ ", slug: "travel"},
    {text: "Summer ☀️", slug: "summer"},
    {text: "Festival 👯‍♂️", slug: "festival"},
    {text: "Flights ✈️ ", slug: "Flights"},
    {text: "Winter 🥶", slug: "winter"},
];
//
// if (process.env.NODE_ENV !== 'production') {
// 	API_BASE_URL = 'http://localhost:3000';
// }

export { API_BASE_URL, IMAGE_BASE_URL, API_URL, LIMIT, SUCCESS200, SUCCESS201, CURRENCY_SYMBOL,TAGS_LIST };
