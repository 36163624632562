import React from 'react'
import PageHeading from "../../../components/pageHeading/PageHeading";

const ChangePassword = (props) => {
    return (
        <div>
            <PageHeading
                heading={"Change Password"}
                profile
            />

            <div className={"p-4 h-[calc(100vh-70px)] overflow-y-auto"}>
            </div>
        </div>
    )
}

export default ChangePassword;
