import React from "react";
import PageHeading from "../components/pageHeading/PageHeading";
import PlanCard from "./components/planCard";

const Upgrade = ({ }) => {
    const plan1 =
    {
        title: "Side Hustle",
        price: "Free for creators",
        uses: "",
        list: [
            "5 pitch credits per month",
            "Automated pitching",
            "Free & instant invoicing"
        ]
    }

    const plan2 =
    {
        title: "Career",
        price: "$40 Per month",
        uses: "Creators making $1000 per month",
        list: [
            "500 pitch credits per month",
            "AI-powered contract analyser",
            "See who brands are working with",
            "All premium templates" +
            "10 Brands requests per month",
            "1-on-1 Expert Coaching",
        ]
    }
    const plan3 =
    {
        title: "Trending",
        price: "$10 Per month",
        uses: "Creators starting to monetize",
        list: [
            "500 pitch credits per month",
            "AI-powered contract analyser",
            "See who brands are working with",
            "All premium templates" +
            "10 Brands requests per month",
            "1-on-1 Expert Coaching",
        ]
    }

    const plan_A = {
        title: "Creator",
        price: "FREE",
        uses: "Best for Creators",
        heading: "",
        list: [
            "50GB storage",
            "UGC template folders",
            "External sharing",
            "Payment processing",
            "Automatic watermarking",
        ]
    }

    const plan_B = {
        title: "Creator Plus",
        price: "£12.99",
        uses: "Coming Soon",
        heading: "Everything in Creator",
        list: [
            "Additional 2TB storage ",
            "Automatic watermarking",
            "External sharing",
            "Payment processing",
            "Contract signing (coming soon)",
            "Approval workflows (coming soon) ",
            "Support",
        ]
    }

    const plan_C = {
        title: "Enterprise",
        price: "Get in touch",
        uses: "Brands & Creator Agencies",
        heading: "Everything in Creator Plus",
        list: [
            "Unlimited storage",
            "White labelling solution",
            "Advanced link sharing",
            "Advanced analytics",
            "24/7 support",
        ]
    }


    return (
        <div>
            <PageHeading
                heading={"Upgrade"}
                profile
            />
            <div className={"flex flex-col items-center justify-center text-white mt-6 mb-[40px]"}>
                <span className={"text-[50px] font-medium"}>Dream Big, keep Hustling! 🚀</span>
                <span className="text-[20px] mt-1">Unlock the true value of your content</span>
            </div>
            <div className={"p-[40px] grid lg:grid-cols-3 gap-10"}>
                <PlanCard theme={true} buttontxt={"Coming Soon"} style={"w-full rounded-xl text-white border border-2 p-4 border-theme-peach-100 [box-shadow:0px_0px_50px_-25px_rgba(137,162,224,69)]"} plan={plan_A} />
                <PlanCard buttontxt={"Get Early Access"} style={"w-full rounded-xl text-white border border-2 border-[#3a3a3a96] p-4"} plan={plan_B} />
                <PlanCard buttontxt={"Enquire Now"} style={"w-full rounded-xl text-white border border-2 border-[#3a3a3a96] p-4"} plan={plan_C} />
            </div>
        </div>
    )
}
export default Upgrade;
