import React, {useState} from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import DefaultTable from "../components/table/defaultTable";
import {FaFileAlt, FaFileContract, FaFileCsv, FaFilePdf} from "react-icons/fa";

const ShareWithMe = (props) => {
    const data = [
        {icon: <FaFilePdf/>, name: "ZXPInstaller.Setup.exe", sharedBy: 'arsh@synswift.com', sharedDate: '10-10-2022'},
        {icon: <FaFileCsv/>, name: "ZXPInstaller.Setup.exe", sharedBy: 'amrinder@synswift.com', sharedDate: '10-10-2022'},
        {icon: <FaFileAlt/>, name: "ZXPInstaller.Setup.exe", sharedBy: 'vinay@client.com', sharedDate: '10-10-2022'},
        {icon: <FaFilePdf/>, name: "ZXPInstaller.Setup.exe", sharedBy: 'singh@synswift.com', sharedDate: '10-10-2022'},
        {icon: <FaFileContract/>, name: "ZXPInstaller.Setup.exe", sharedBy: 'john@test.com', sharedDate: '10-10-2022'},
    ];

    return (
        <div>
            <PageHeading
                heading={"Shared with me"}
                profile
            />

            <div className={"p-4 h-[calc(100vh-70px)] overflow-y-auto"}>
                <DefaultTable data={data}/>
            </div>
        </div>
    )
}

export default ShareWithMe;
