import { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import { UploaderContext, UploaderProvider } from '../../context/uploader.context';
import UploadAccordion from '../main/components/accordion/UploadAccordion.jsx';

const SubMainRoute = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { filesUploading, showAccordion, fetchStorage, handleCloseAccordion } = useContext(UploaderContext);

    useEffect(() => {
        fetchStorage()
    }, [])

    return (
        <div className="bg-theme-2">
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="relative flex flex-1 flex-col">
                    <main>
                        <div className="mx-auto max-w-screen-2xl h-[calc(100vh)] overflow-y-auto">
                            <Outlet />
                        </div>
                        {/*Earnings Bar*/}
                        {
                            Object.values(filesUploading).length > 0 ?
                                <UploadAccordion
                                    show={Object.values(filesUploading).length && showAccordion}
                                    onClose={handleCloseAccordion}
                                    currentUploading={Object.values(filesUploading)}
                                />
                                : null
                        }
                    </main>
                </div>
            </div>
        </div>
    );
};

export default function MainRoute(props) {
    return (
        <UploaderProvider>
            <SubMainRoute {...props} />
        </UploaderProvider>
    );
}
