import React from 'react';

function UploadHeader(props) {
	return (
		<div className="my-5 px-[30px]">
			<div className="text-white text-[20px]">
				<h2>{props.files.length} Files Uploading</h2>
			</div>
			<div className="right">
			</div>
		</div>
	);
}

export default UploadHeader;
