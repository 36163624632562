import React, { useContext, useEffect, useRef, useState } from 'react'
import UploadInput from '../../../../upload/components/UploadInput'
import { getUserProfile, uploadWatermarkImage } from '../../../../../../service/service.user';
import { selectDateFormat, selectLanguageOptions, selectTimezoneFormat } from '../../../../../../utils/Data'
import { IoChevronBack } from "react-icons/io5";
import Button from '../../../../components/buttons/Button.jsx'
import { LiaExchangeAltSolid } from "react-icons/lia";
import MainLoader from '../../../../components/loading/MainLoader.jsx';
import NoThumb from '../../../../../../assets/images/no-thumb.jpg'
import toast from '../../../../../../utils/toast.js'
import { UserContext } from '../../../../../../context/user.context.js';


const Watermark = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [watermark, setWatermark] = useState("");
    const [fetch, setFetch] = useState(false);

    useEffect(() => {
        const userData = user || JSON.parse(localStorage.getItem("user"));
        if (userData && !userData?.username) return
        setFetch(true)
        getUserProfile(userData.username).then((res) => {
            if (res && res?.data) {
                setWatermark(res.data?.watermarkImage);
                setFetch(false)
            }
        }).catch(() => {
            setFetch(false)
        })
    }, []);

    const uploadFile = (formData) => {
        uploadWatermarkImage(formData)
            .then((res) => {
                if (res && res.data && res.data.path) {
                    setWatermark(res.data.path);
                    setShow(false)
                    setIsLoading(false)
                    toast.success(res.data?.message)
                }
            })
            .catch((err) => {
                setShow(true)
                setIsLoading(false)
                console.log(err)
            })
            .finally(() => setIsLoading(false));
    }

    const handleFileUpload = (files) => {
        const file = files[0];
        if (!file) {
            return;
        }
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
            toast.error('Unsupported file format. Please upload a PNG or JPG file.');
            return;
        }
        setShow(false)
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", file);
        uploadFile(formData)
    }

    return (
        !show && watermark ? <div className="relative p-4 text-white" style={{ maxHeight: '250px' }}>
            <div className='flex justify-between items-center pb-2 border-b border-black/50'>
                <p>
                    Watermark Preview
                </p>
                <Button icon_position='right' icon={<LiaExchangeAltSolid className='text-[16px] mr-2' />} onClick={() => setShow(!show)} name={"Change"} />
            </div>
            {fetch || isLoading ? <MainLoader /> :
                <img
                    onError={(e) => {
                        e.target.src = NoThumb;
                    }}
                    src={watermark}
                    className='mt-4 m-auto h-[250px] w-[250px] object-unset'
                />
            }

        </div>
            :
            <div className='p-4'>
                {watermark ? <div className='flex justify-start gap-2 items-center text-white cursor-pointer' onClick={() => setShow(!show)}>
                    <Button icon_position='right' icon={<IoChevronBack className='text-[18px]' />} />
                    Back
                </div> : null}
                <div className={"mt-[45px]"}>

                    <div className={"flex flex-col text-white mb-4"}>
                        <span className={"text-[40px] font-medium"}>Update Your Logo</span>
                        <span className={"text-[14px] font-light"}>
                            Upload a new logo to use as a watermark
                        </span>
                    </div>
                    <div className=''>
                        <UploadInput multiple={false} handleFileUpload={handleFileUpload} info={"Files must be in PNG or JPG with max size of 100 MB."} />
                    </div>
                </div>

            </div>
    )
}

export default Watermark
