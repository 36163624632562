import {Menu} from '@headlessui/react'
import {Fragment, useState} from "react";
import {FaChevronDown} from "react-icons/fa";
import {NavLink} from "react-router-dom";

const MenuDropdown = ({name, icon, subMenu, path, location, active}) => {
    const [menuOpened, setMenuOpened] = useState(false)

    return (
        <Menu>
            <Menu.Button
                onClick={() => setMenuOpened(!menuOpened)}
                className={
                    `${active === true && "bg-black"},${location.pathname === path ? 'bg-theme-2' : ''}
                 flex justify-between items-center py-2 pl-4 font-bold text-[14px] text-white h-12 text-left  rounded-sm
               `}>

                <div className={"flex items-center gap-2"}>{icon}{name}</div>
                <FaChevronDown/>
            </Menu.Button>
            <Menu.Items className={"pl-5"} static>
                {menuOpened && (
                    subMenu.map((item, index) => {
                        return (
                            <NavLink
                                to={item.path}
                                key={index}
                                className={`${active && 'bg-blue-500'}
                                    flex gap-2 items-center py-2 px-4 font-medium text-[14px] text-white h-10 text-left
                                    hover:bg-theme-2  rounded-sm
                                    `}
                            >
                                {item.name}
                            </NavLink>
                        )
                    })
                )}
            </Menu.Items>
        </Menu>
    )
}
export default MenuDropdown;
