import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import DefaultInput from "../inputs/DefaultInput";
import SelectInput from "../inputs/SelectInput";
import { IoAdd, IoClose } from "react-icons/io5";
import { BiEuro } from "react-icons/bi";
import SwitchInput from "../inputs/SwitchInput";
import { selectFolderTypes } from '../../../../utils/Data';
import { createFolders } from '../../../../service/service.folder';
import { FileManagerContext } from '../../../../context/FileManagerContext';
import { useParams } from 'react-router-dom';
import { SUCCESS200, SUCCESS201 } from '../../../../constants/constants';
import toast from '../../../../utils/toast'
import { useFieldArray, useForm } from 'react-hook-form';
import ValidateInput from '../inputs/ValidateInput';
import { AiOutlinePercentage } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import Button from '../../components/buttons/Button'
import { MdDelete } from "react-icons/md";
import { BsCurrencyPound } from 'react-icons/bs';


export default function AddFolderModal({ isOpen, modalRef = null, closeModal }) {
    const [type, setType] = useState(selectFolderTypes[0]?.value || 0);
    const { loadFolders = null, folderIdData } = useContext(FileManagerContext);
    const [loading, setLoading] = useState(false);
    const [adPrice, setAdPrice] = useState(false);
    const [rawPrice, setRawPrice] = useState(false);
    const [miscellaneousPrice, setMiscellaneousPrice] = useState(false);
    const [total, setTotal] = useState(0);
    const [rawPriceValue, setRawPriceValue] = useState(0);
    const [miscellaneousData, setMiscellaneousData] = useState([
        {
            hook: "",
            price: 0,
        },
    ]);

    const [adPriceForm, setadPriceForm] = useState({
        month: "",
        price: ""
    })
    const [basePrice, setBasePrice] = useState(0);
    const [adPriceValue, setAdPriceValue] = useState(0);
    const [adMonthValue, setAdMontheValue] = useState(0);
    const [folderId, setFolderId] = folderIdData;
    const { id } = useParams();

    const { formState: { errors }, register, reset, unregister, setValue, control, clearErrors, handleSubmit } = useForm({
        defaultValues: {
            adPriceForm,
            miscellaneousData
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'miscellaneousData',
    });

    const onChangeAdPriceForm = (name, value) => {
        const filed = name.split(".").length > 1 ? name.split(".")[1] : name
        setValue(name, value);
        setadPriceForm({ ...adPriceForm, [filed]: value })
    }

    const handleShowAdprice = (name, value) => {
        clearErrors('adPriceForm');
        unregister('adPriceForm');
        setAdPrice(value)
    }
    const handleShowRawPrice = (name, value) => {
        setRawPrice(value)
    }
    const handleShowMiscellaneousPrice = (name, value) => {
        clearErrors('miscellaneousData');
        unregister('miscellaneousData');
        setMiscellaneousPrice(value)
    }

    useEffect(() => {
        let total = 0;
        if (basePrice) {
            total += Number.parseFloat(basePrice);
            if (adPrice && adPriceForm.month && adPriceForm.price) {
                total += Number.parseFloat((basePrice / 100) * adPriceForm.price * adPriceForm.month);
            }
            if (rawPrice && rawPriceValue) {
                total += Number.parseFloat((basePrice / 100) * rawPriceValue);
            }
        }

        if (miscellaneousPrice && miscellaneousData && miscellaneousData.length > 0) {
            for (let data of miscellaneousData) {
                if (data && data.price > 0) {
                    total += Number.parseFloat(data.price);
                }
            }
        }

        setTotal(Math.round(total));
    }, [basePrice, adPrice, adPriceForm.price, adPriceForm.month, rawPrice, rawPriceValue, miscellaneousPrice, miscellaneousData]);


    const onSubmit = (formData) => {
        setLoading(true);
        const data =
            type != 1
                ? {
                    name: formData.name,
                    type,
                }
                : {
                    name: formData.name,
                    type,
                    usage_price: basePrice,
                    enable_usage: adPrice,
                    ad_price: adPrice ? adPriceForm.price : 0,
                    ad_price_month: adPrice ? adPriceForm.month : 0,
                    raw_usage: rawPrice,
                    raw_price: rawPrice ? rawPriceValue : 0,
                    misc_usage: miscellaneousPrice,
                    misc: miscellaneousPrice ? miscellaneousData : 0,
                };
        createFolders(id ? { ...data, parent_id: folderId } : data)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success(`Folder (${formData.name}) is created successfully!`);
                    loadFolders(id);
                    closeModal()
                } else {
                    toast.error("Unable to create a folder!!");
                }
            })
            .catch((err) => {
                toast.error("Unable to create a folder!!");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateMiscellaneousData = async (value, index, type) => {
        setValue(`miscellaneousData[${index}].hook`, type === 1 ? value : "");
        setValue(`miscellaneousData[${index}].price`, type === 2 ? value : "");
        setMiscellaneousData(prevMiscellaneousData => {
            const updatedData = prevMiscellaneousData.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        hook: type === 1 ? value : item.hook,
                        price: type === 2 ? value : item.price,
                    };
                }
                return item;
            });
            return updatedData;
        });
    };


    const addMiscellaneous = () => {
        setMiscellaneousData([
            ...miscellaneousData,
            {
                price: 0,
                hook: "",
            },
        ])
    }


    const registerFied = (ref, index) => {
        const refrence = `miscellaneousData[${index}].hook`;
        register(refrence, { required: true })
    }

    const registerAdPriceForm = (value) => {
        const refrence = value
        register(refrence, { required: true })
    }

    const removeMiscellaneous = (index) => {
        remove(index)
        miscellaneousData.splice(index, 1);
        setMiscellaneousData([...miscellaneousData]);
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" ref={modalRef} onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/70" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-[10px] bg-theme-3 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white mb-5"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span>Add Folder</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                    </div>
                                </Dialog.Title>
                                <div className="mt-2 flex flex-col gap-2">
                                    <ValidateInput
                                        bg="bg-theme-5"
                                        label={"Folder Name"}
                                        placeholder={"Add Folder Name"}
                                        error={errors?.name}
                                        name={"name"}
                                        register={register}
                                    />

                                    <SelectInput
                                        options={selectFolderTypes}
                                        defaultValue={type}
                                        onChange={(ev) => setType(parseInt(ev.target?.value))}
                                        label={"Folder Type"}
                                    />
                                    {type === 1 ?
                                        <>
                                            <DefaultInput
                                                label={"UGC Base Price"}
                                                type={"number"}
                                                placeholder={""}
                                                icon={<BsCurrencyPound/>}
                                                value={basePrice}
                                                onChange={(name, value) => setBasePrice(value)}
                                                bg="bg-neutral"
                                            />
                                            <PriceConditions
                                                showInput={adPrice}
                                                adPriceForm={adPriceForm}
                                                onChangeAdPriceForm={onChangeAdPriceForm}
                                                registerFied={registerAdPriceForm}
                                                error={errors}
                                                onChange={handleShowAdprice} />
                                            <RawConditions
                                                rawPriceValue={rawPriceValue}
                                                onChangeForm={(name, value) => setRawPriceValue(value)}
                                                showInput={rawPrice}
                                                onChange={handleShowRawPrice} />
                                            <MiscellaneousConditons
                                                miscellaneousData={miscellaneousData}
                                                updateMiscellaneousData={updateMiscellaneousData}
                                                removeMiscellaneous={removeMiscellaneous}
                                                showInput={miscellaneousPrice}
                                                addMiscellaneous={addMiscellaneous}
                                                registerFied={registerFied}
                                                error={errors}
                                                onChange={handleShowMiscellaneousPrice} />
                                        </> : null}

                                </div>

                                <div className="mt-4 flex justify-between item-center">
                                    <span className='text-white pl-1'>
                                        {type === 1 &&
                                            <span>
                                                Total: <b>£{total}</b>
                                            </span>
                                        }
                                    </span>

                                    <div className='gap-3 flex'>
                                        <ModalButton
                                            disabled={loading}
                                            name={loading ? "Processing..." : "Save"}
                                            onClick={handleSubmit(onSubmit)}
                                        />
                                        <ModalButton
                                            disabled={false}
                                            name={"Cancel"}
                                            onClick={closeModal}
                                        />
                                    </div>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const ModalButton = ({ onClick, disabled, name }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}

const PriceConditions = ({ showInput, error, registerFied, onChange, adPriceForm, onChangeAdPriceForm }) => {
    return (
        <div className={"w-full text-[14px] text-white font-medium"}>
            <div className={"w-full h-10 flex justify-between items-center"}>
                <span>AD Price</span>
                <SwitchInput
                    onChange={onChange}
                    checked={showInput}
                />
            </div>
            {showInput &&
                <div className='flex justfiy-between item-center gap-2'>
                    <DefaultInput
                        value={adPriceForm.month}
                        name="adPriceForm.month"
                        onChange={onChangeAdPriceForm}
                        type={"number"}
                        placeholder={"Months"}
                        ref={registerFied("adPriceForm.month")}
                        error={error.adPriceForm ? error.adPriceForm?.month : null}
                        bg="bg-neutral"
                    />
                    <DefaultInput
                        value={adPriceForm.price}
                        name={"price"}
                        onChange={onChangeAdPriceForm}
                        type={"number"}
                        placeholder={"% of base price"}
                        icon={<AiOutlinePercentage />}
                        bg="bg-neutral"
                    />
                </div>
            }
        </div>
    )
}

const RawConditions = ({ showInput, onChange, rawPriceValue, onChangeForm }) => {
    return (
        <div className={"w-full text-[14px] text-white font-medium"}>
            <div className={"w-full h-10 flex justify-between items-center"}>
                <span>RAW Footage Price</span>
                <SwitchInput
                    onChange={onChange}
                    checked={showInput}
                />
            </div>
            {showInput &&
                <DefaultInput
                    value={rawPriceValue}
                    name={"rawPriceValue"}
                    onChange={onChangeForm}
                    type={"number"}
                    placeholder={"% of base price"}
                    icon={<AiOutlinePercentage />}
                    bg="bg-neutral"
                />
            }
        </div>
    )
}

const MiscellaneousConditons = ({
    showInput,
    onChange,
    miscellaneousData,
    updateMiscellaneousData,
    removeMiscellaneous,
    addMiscellaneous,
    registerFied,
    error
}) => {
    return (
        <div className={"w-full text-[14px] text-white font-medium"}>
            <div className={"w-full h-10 flex justify-between items-center"}>
                <span>Miscellaneous</span>
                <SwitchInput
                    onChange={onChange}
                    checked={showInput}
                />
            </div>
            {showInput &&
                miscellaneousData.map((item, index) => {
                    return <div key={index} className='flex justfiy-between item-center gap-2'>
                        <DefaultInput
                            type={"text"}
                            placeholder={"Hook"}
                            bg="bg-neutral"
                            value={item.hook}
                            onChange={(name, value) => updateMiscellaneousData(value, index, 1)}
                            required={true}
                            name={`miscellaneousData[${index}].hook`}
                            ref={registerFied(true, index)}
                            error={error?.miscellaneousData && error?.miscellaneousData[index] ? error?.miscellaneousData[index]?.hook : null}
                        />
                        <DefaultInput
                            type={"number"}
                            value={item.price}
                            icon={<BsCurrencyPound  />}
                            bg="bg-neutral"
                            name={`miscellaneousData[${index}].price`}
                            onChange={(name, value) => updateMiscellaneousData(value, index, 2)}
                            required={true}
                            ref={registerFied(false, index)}
                        />
                        <div className='mt-1'>
                            {index == 0 ?
                                <Button
                                    onClick={() => addMiscellaneous()}
                                    icon={<MdAdd className='text-[20px]' />} /> :
                                <Button
                                    onClick={() => removeMiscellaneous(index)}
                                    icon={<MdDelete className='text-[20px]' />} />
                            }
                        </div>
                    </div>
                })

            }
        </div>
    )
}
