import React, {useState} from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import WelcomeMessage from "./components/WelcomeMessage";
import DataCards from "./components/DataCards";
import { Tab } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Home = (props) => {
    let [categories] = useState({
        CreatorCloud: [
            {
                id: 1,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
                commentCount: 5,
                shareCount: 2,
            },
            {
                id: 2,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
                commentCount: 3,
                shareCount: 2,
            },
        ],
        CreatorStock: [
            {
                id: 1,
                title: 'Is tech making coffee better or worse?',
                date: 'Jan 7',
                commentCount: 29,
                shareCount: 16,
            },
            {
                id: 2,
                title: 'The most innovative things happening in coffee',
                date: 'Mar 19',
                commentCount: 24,
                shareCount: 12,
            },
        ],
    })
    return (
        <div>
            <PageHeading
                heading={"Dashboard"}
                profile
            />

            <div className={"p-4"}>
                <WelcomeMessage/>
                <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-black/20 p-1 w-[400px]">
                        {Object.keys(categories).map((category) => (
                            <Tab
                                key={category}
                                className={({selected}) =>
                                    classNames(
                                        'w-full rounded-lg py-2.5 text-[14px] text-gray-200 font-medium leading-5',
                                        'ring-0 ring-offset-0  focus:outline-none focus:ring-0',
                                        selected
                                            ? 'bg-theme-3 text-gray-100 shadow'
                                            : 'text-gray-100 hover:bg-theme-2 hover:text-gray-200'
                                    )
                                }
                            >
                                {category}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {Object.values(categories).map((posts, idx) => (
                            <Tab.Panel
                                key={idx}
                                className={classNames(
                                    'rounded-xl p-3',
                                    'ring-0 ring-offset-0 focus:outline-none focus:ring-0'
                                )}
                            >
                                <DataCards/>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}

export default Home;
