import React, { useContext, useEffect, useState } from "react";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import DrawerContainer from "../../../components/drawer/drawerContainer";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/user.context";
import defaultImage from '../../../../assets/images/user.jpg'

const PageHeading = ({ heading, profile }) => {
    const [theme, setTheme] = useState("light");
    const { user } = useContext(UserContext)

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const onSwitchTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    }

    return (
        <div className={"w-full h-[70px] border-b border-black/50 flex justify-between items-center text-white px-4"}>
            <div className={"flex gap-2"}>
                <Link to={"/"}>
                    <img className={"w-10 lg:hidden"}
                        src={"https://creatorstock.io/static/media/creatorstock.1f4ebc4c1d8192f70734bdbc203fefce.svg"}
                        alt={"svg"}
                    />
                </Link>
                <h3 className="font-bold text-lg">{heading}</h3>
            </div>

            <div className={"items-center gap-4 hidden lg:flex"}>
                <div className={"dark:bg-red bg-green-500 w-[40px] items-center h-10 rounded-[100px] " +
                    " bg-white/10 overflow-hidden cursor-pointer"}>
                    <span
                        className={`flex flex-1 group h-full justify-center items-center transition
                         ${theme === "light" ? "bg-black" : "bg-white text-black"}
                        `}
                        onClick={onSwitchTheme}
                    >
                        {
                            theme === 'light' ? <MdOutlineDarkMode className="group-hover:animate-pulse" /> :
                                <MdOutlineLightMode className="group-hover:animate-pulse" />
                        }

                    </span>
                </div>
                {profile ?
                    <div className={"flex items-center gap-2"}>
                        <div className={"flex flex-col"}>
                            <span className={"text-[14px] font-medium"}>{user.name || "..."}</span>
                            <span className={"text-[12px]"}>{user.username}</span>
                        </div>
                        <div className={"w-10 h-10 rounded-full bg-gray-300"}>
                            <img className="w-10 h-10 object-cover rounded-full"
                                onError={(e) => {
                                    e.target.src = defaultImage;
                                }}
                                src={user.profileImage || defaultImage}
                                alt={"user-image"} />
                        </div>
                    </div>
                    :
                    <div>
                    </div>
                }
            </div>
            <div className={"lg:hidden flex"}>
                <DrawerContainer />
            </div>
        </div>
    )
}
export default PageHeading;
