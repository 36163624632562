import React, { useState, createContext } from "react";
import { convertSize, currentTime, getDateStructured } from "../utils/Utils";
import { folderList } from "../utils/Data";
import { getFolders, getPublicFolders } from "../service/service.folder";
import { SUCCESS200, SUCCESS201 } from "../constants/constants";
import moment from "moment";
import NoThumb from "../assets/images/content2.jpg";

export const FileManagerContext = createContext();

export const FileManagerContextProvider = (props) => {
  const [data, setData] = useState(folderList);
  const [plan, setPlan] = useState("Starter");
  const [folderName, setFolderName] = useState("");
  const [folderId, setFolderId] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [folderInfo, setFolderInfo] = useState({});
  const [folderError, setFolderError] = useState("");

  // Creates a new folder
  const createFolder = (filename, svg) => {
    let newFolder = {
      id: data.length + 1,
      meta: {
        type: "folder",
        name: filename,
        svg: svg,
        time: currentTime(),
        date: getDateStructured(new Date()),
        size: 0,
        starred: false,
      },
      subFolder: [],
    };
    let defaultData = data;
    let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    let findFolder = defaultData.findIndex((item) => item.id === Number(folderId));
    if (folderId !== "" && defaultData[findFolder] !== undefined) {
      defaultData[findFolder].subFolder.push({ fileId: data.length + 1 });
      defaultData = [newFolder, ...defaultData];
      setData(defaultData);
    } else {
      setData([newFolder, ...defaultData]);
    }
  };

  // Creates a file
  const createFile = (files) => {
    let defaultData = data;
    let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    let findFolder = defaultData.findIndex((item) => item.id === Number(folderId));
    if (folderId !== "" && defaultData[findFolder] !== undefined) {
      let fileArray = [];
      files.forEach((item) => {
        fileArray.push({ fileId: item.id });
      });
      defaultData[findFolder].subFolder = [...defaultData[findFolder].subFolder, ...fileArray];
      defaultData = [...defaultData, ...files];
      setData([...defaultData]);
    } else {
      defaultData = [...defaultData, ...files];
      setData([...defaultData]);
    }
  };

  // Download a folder/file
  const downloadFile = (file) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = "data:" + file.meta.ext + ";charset=utf-8," + encodeURIComponent(file.meta.name);
    downloadLink.download = file.meta.name;
    downloadLink.click();
  };

  // Downloads all the selected file
  const selectorDownloadFile = () => {
    let defaultData = data;
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      downloadFile(file);
    });
  };

  // Copy a folder/file
  const copyToFolder = (file, folder) => {
    let defaultData = data;

    if (folder !== "") {
      let findNewFolderIndex = defaultData.findIndex((item) => item.id === folder);

      defaultData[findNewFolderIndex].subFolder.push({ fileId: file.id });
      setData([...defaultData]);
    }
  };

  // Selector copy for lists
  const selectorCopyToFolder = (folder) => {
    let defaultData = data;
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      copyToFolder(file, folder);
    });
  };

  // Move a folder/filer
  const moveFolder = (currentFolder, file, folder) => {
    let defaultData = data;
    if (folder !== "") {
      if (currentFolder === null) {
        copyToFolder(file, folder);
      } else {
        let findNewFolderIndex = defaultData.findIndex((item) => item.id === folder);
        let findPrevFolderIndex = defaultData.findIndex((item) => item.id === Number(currentFolder));
        defaultData[findNewFolderIndex].subFolder.push({ fileId: file.id });
        defaultData[findPrevFolderIndex].subFolder = defaultData[findPrevFolderIndex].subFolder.filter(
          (item) => item.fileId !== file.id
        );
        setData([...defaultData]);
      }
    }
  };

  // Shares a file/folder
  const shareFiles = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    defaultData[found].shared = { sharedTime: currentTime(), sharedDate: "Today" };
    defaultData[found].meta.members = [{ user: "Illiash Hossain", theme: "purple" }];
    setData([...defaultData]);
  };

  // Stars a folder/file
  const onStarClick = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (defaultData[found].meta.starred) {
      defaultData[found].meta.starred = false;
    } else {
      defaultData[found].meta.starred = true;
    }
    setData([...defaultData]);
  };

  // Checks a file/folder
  const onFileCheck = (ev, id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (ev.target.checked) {
      defaultData[found].meta.checked = true;
      setData([...defaultData]);
    } else {
      defaultData[found].meta.checked = false;
      setData([...defaultData]);
    }
  };

  // Deletes a file/folder
  const deleteFolder = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    defaultData[found]["recovery"] = { deletedAt: "Today", deletedOn: currentTime() };
    setData([...defaultData]);
  };

  // Deletes all the selected file
  const selectorDeleteFolder = () => {
    let defaultData = data;
    let checkedItems = defaultData.filter((item) => item.meta.checked === true);
    checkedItems.forEach((file) => {
      deleteFolder(file.id);
    });
  };

  // Restores a file
  const restoreFolder = (id) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    delete defaultData[found]["recovery"];

    setData([...defaultData]);
  };

  // Removes shares
  const removeShare = (id, type) => {
    let defaultData = data;
    let found = defaultData.findIndex((item) => item.id === id);
    if (type === "incoming") {
      defaultData[found].meta.members = [];
      setData([...defaultData]);
    } else if (type === "outgoing") {
      defaultData[found].shared = false;
      setData([...defaultData]);
    } else {
      defaultData[found].meta.link = false;
      setData([...defaultData]);
    }
  };

  // Permanently deletes folders/files
  const permanentDelete = (id) => {
    let defaultData = data;
    defaultData = defaultData.filter((item) => item.id !== id);
    setData([...defaultData]);
  };

  // Returns the total size for a folder;
  const getTotalSize = (folder) => {
    let folderChildren = folder.subFolder;
    let size = 0;
    if (folderChildren.length === 0) {
      return size;
    } else {
      folderChildren.forEach((el) => {
        let file = getFiles(el.fileId);
        if (file === undefined) {
          size = size + 0;
        } else {
          size = size + file.meta.size;
        }
      });
      return size;
    }
  };

  const getFiles = (id) => {
    return data.find((item) => item.id === id);
  };

  const loadFolders = async (id, publicData, subId, params) => {
    setIsLoading(true);
    if (!id) {
      setFolderName("");
      setFolderId(null);
      setFolderInfo({});
    }
    if (publicData) {
      getPublicFolders(id, subId)
        .then((res) => {
          if ((res.status === SUCCESS200 || res.status === SUCCESS201) && res.data && res.data.status === SUCCESS200) {
            if (id) {
              setFolderName(res.data.currentFolder.name);
              setFolderId(res.data.currentFolder.id);
              setFolderInfo(res.data.currentFolder);
            }
            const newFolders = res.data.folders.map((folder) => {
              return {
                id: folder.uuid,
                folder,
                meta: {
                  name: folder.name,
                  type: "folder",
                  svg: folder.can_delete ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                      <g>
                        <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: "#f29611" }} />
                        <path
                          d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                          style={{ fill: "#ffb32c" }}
                        />
                        <path
                          d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z"
                          style={{ fill: "#f2a222" }}
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                      <g>
                        <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: "#6c87fe" }}></rect>
                        <path
                          d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                          style={{ fill: "#8aa3ff" }}
                        ></path>
                        <path
                          d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z"
                          style={{ fill: "#798bff" }}
                        ></path>
                        <path
                          d="M42.2227,40H41.5V37.4443a5.5,5.5,0,0,0-11,0V40h-.7227A2.8013,2.8013,0,0,0,27,42.8184v6.3633A2.8013,2.8013,0,0,0,29.7773,52H42.2227A2.8013,2.8013,0,0,0,45,49.1816V42.8184A2.8013,2.8013,0,0,0,42.2227,40ZM36,48a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,36,48Zm3.5-8h-7V37.4443a3.5,3.5,0,0,1,7,0Z"
                          style={{ fill: "#4b66bc" }}
                        ></path>
                      </g>
                    </svg>
                  ),
                  time: moment(folder.created_at).format("hh:mm A"),
                  date: moment(folder.created_at).format("MM/DD/YYYY"),
                  canDelete: folder.can_delete,
                },
              };
            });
            if (id) {
              const newContent = res.data.contents.map((content) => {
                return {
                  id: content.uuid,
                  meta: {
                    name: content.original_name || "-",
                    image: content.thumbnail || NoThumb,
                    type: "file",
                    time: moment(content.created_at).format("hh:mm A"),
                    date: moment(content.created_at).format("MM/DD/YYYY"),
                    size: content.file_size ? convertSize(content.file_size) : convertSize(0),
                    rejectedReason: content.rejectedReason,
                    approved: content.approved,
                    processing:
                      content.published === 5 || content.isRejected || content.deleted_at
                        ? "#fa3e3e"
                        : content.published === 1
                          ? "#1d2d40"
                          : "#fc723b",
                    published: content.published,
                    isRejected: content.isRejected,
                    deleted_at: content.deleted_at,
                  },
                };
              });
              setFolders([...newFolders, ...newContent]);
            } else {
              setFolders([...newFolders]);
            }
          } else {
            setFolders([]);
            setFolderName("");
            setFolderId(null);
            setFolderInfo({});
          }
        })
        .catch((err) => {
          console.log(err);
          setFolders([]);
          setFolderName("");
          setFolderId(null);
          setFolderInfo({});
          if (err.response && err.response.data) {
            setFolderError(err.response.data.message || "Unable to locate your folder");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getFolders(id, { ...params })
        .then((res) => {
          if ((res.status === SUCCESS200 || res.status === SUCCESS201) && res.data && res.data.status === SUCCESS200) {
            if (id) {
              setFolderName(res.data.currentFolder.name);
              setFolderId(res.data.currentFolder.id);
              setFolderInfo(res.data.currentFolder);
            }
            const newFolders = res.data.folders.map((folder) => {
              return {
                id: folder.uuid,
                folder,
                meta: {
                  name: folder.name,
                  type: "folder",
                  svg: folder.can_delete ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                      <g>
                        <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: "#f29611" }} />
                        <path
                          d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                          style={{ fill: "#ffb32c" }}
                        />
                        <path
                          d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z"
                          style={{ fill: "#f2a222" }}
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                      <g>
                        <rect x="32" y="16" width="28" height="15" rx="2.5" ry="2.5" style={{ fill: "#6c87fe" }}></rect>
                        <path
                          d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                          style={{ fill: "#8aa3ff" }}
                        ></path>
                        <path
                          d="M8.015,59c2.169,2.3827,4.6976,2.0161,6.195,2H58.7806a6.2768,6.2768,0,0,0,5.2061-2Z"
                          style={{ fill: "#798bff" }}
                        ></path>
                        <path
                          d="M42.2227,40H41.5V37.4443a5.5,5.5,0,0,0-11,0V40h-.7227A2.8013,2.8013,0,0,0,27,42.8184v6.3633A2.8013,2.8013,0,0,0,29.7773,52H42.2227A2.8013,2.8013,0,0,0,45,49.1816V42.8184A2.8013,2.8013,0,0,0,42.2227,40ZM36,48a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,36,48Zm3.5-8h-7V37.4443a3.5,3.5,0,0,1,7,0Z"
                          style={{ fill: "#4b66bc" }}
                        ></path>
                      </g>
                    </svg>
                  ),
                  time: moment(folder.created_at).format("hh:mm A"),
                  date: moment(folder.created_at).format("MM/DD/YYYY"),
                  canDelete: folder.can_delete,
                },
              };
            });
            if (id || params?.term) {
              const newContent = res.data.contents.map((content) => {
                return {
                  id: content.uuid,
                  meta: {
                    name: content.original_name || "-",
                    image: content.thumbnail || NoThumb,
                    type: "file",
                    tags: content.tags || [],
                    time: moment(content.created_at).format("hh:mm A"),
                    date: moment(content.created_at).format("MM/DD/YYYY"),
                    size: content.file_size ? convertSize(content.file_size) : convertSize(0),
                    rejectedReason: content.rejectedReason,
                    approved: content.approved,
                    processing:
                      content.published === 5 || content.isRejected || content.deleted_at
                        ? "#fa3e3e"
                        : content.published === 1
                          ? "#1d2d40"
                          : "#fc723b",
                    published: content.published,
                    isRejected: content.isRejected,
                    deleted_at: content.deleted_at,
                  },
                };
              });
              setFolders([...newFolders, ...newContent]);
            } else {
              setFolders([...newFolders]);
            }
          } else {
            setFolders([]);
            setFolderName("");
            setFolderId(null);
            setFolderInfo({});
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <FileManagerContext.Provider
      value={{
        contextData: [data, setData],
        planData: [plan, setPlan],
        folderData: [folders, setFolders],
        loadingData: [isLoading, setIsLoading],
        folderNameData: [folderName, setFolderName],
        folderIdData: [folderId, setFolderId],
        folderInfo,
        folderError,
        createFolder: createFolder,
        createFile: createFile,
        onStarClick: onStarClick,
        deleteFolder: deleteFolder,
        selectorDeleteFolder: selectorDeleteFolder,
        restoreFolder: restoreFolder,
        removeShare: removeShare,
        shareFiles: shareFiles,
        onFileCheck: onFileCheck,
        downloadFile: downloadFile,
        selectorDownloadFile: selectorDownloadFile,
        copyToFolder: copyToFolder,
        selectorCopyToFolder: selectorCopyToFolder,
        moveFolder: moveFolder,
        permanentDelete: permanentDelete,
        getTotalSize: getTotalSize,
        loadFolders: loadFolders,
      }}
    >
      {props.children}
    </FileManagerContext.Provider>
  );
};
