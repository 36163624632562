import React, {useState} from 'react';
import {WithContext as ReactTags} from 'react-tag-input';

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const TagInput = ({tags, updateTags}) => {
    const [suggestions, setSuggestions] = useState([]);
    const handleDelete = i => {
        updateTags(tags.filter((tag, index) => index !== i));
    };
    const handleAddition = tag => {
        updateTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        // re-render
        updateTags(newTags);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const handleInput = (val) => {
        console.log(val);
    }

    return (
        <ReactTags
            tags={tags}
            //suggestions={suggestions}
            className="w-full h-10 text-[14px] bg-theme-5 rounded-[5px] px-[6px] py-[5px]"
            id="slub"
            text="name"
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="inline"
            handleInputChange={handleInput}
            autocomplete
        />
    );
};

export default TagInput;
