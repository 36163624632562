import { useContext } from "react";
import { UserContext } from "../../../../context/user.context";

const WelcomeMessage = () => {
    const { user } = useContext(UserContext)
    return (
        <div className={"flex flex-col py-4 text-white"}>
            <span className={"text-[40px] font-medium"}>👋 Hey {user?.name}!</span>
            <span className={"text-[14px] font-light"}>
                Great to see you! Lets get creative.
            </span>
        </div>
    )
}
export default WelcomeMessage;
