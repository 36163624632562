import { createContext, useState } from 'react';
import { getCookie, resetCookie } from '../utils/Utils';
import localStorage from '../utils/localStorage';

export const UserContext = createContext({
    user: {
        userData: {},
        isAuthenticated: false,
    },
    logout: () => null,
    login: () => null,
    updateUserProfile: () => null,
    profileUpdateRequest: () => null
});

const INITIAL_STATE = {
    userData: {},
    token: null,
    isAuthenticated: false,
};

const initialState = () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (user && token) {
        const userData = user
        return {
            ...userData,
            token,
            isAuthenticated: true
        };
    } else {
        resetCookie();
        // window.location.href = "https://creatorstock.io/";
    }
    return INITIAL_STATE;
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(initialState);

    const login = (data) => {
        localStorage.setItem("user", data.user);
        localStorage.setItem("token", data.access_token);
        setUser({
            ...data.user,
            token: data.access_token,
            isAuthenticated: true,
        });
    };

    const updateUserProfile = (userData) => {
        setUser({
            ...userData,
        });
        localStorage.setItem("user", userData)
    };

    const profileUpdateRequest = (data) => {
        const newData = { ...data };
        newData.token = user.access_token;
        newData.isAuthenticated = true;
        setUser({ ...newData });
        localStorage.setItem("user", newData);
    };

    const logout = () => {
        setUser({ ...INITIAL_STATE });
    };


    return <UserContext.Provider value={{ user, logout, login, updateUserProfile, profileUpdateRequest }}>{children}</UserContext.Provider>;
};