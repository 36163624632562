import React from 'react'

const NotFound = ({ content }) => {
    return (
        <div className="h-[300px] flex flex-col justify-center items-center text-white text-[14px]">
            {content}
        </div>
    )
}

export default NotFound
