import React, { useContext, useEffect, useState } from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import UploadCard from "./components/UploadCard";
import UploadInput from './components/UploadInput';
import { useNavigate } from "react-router-dom";
import { UploaderContext } from '../../../context/uploader.context';
import { submitContent } from '../../../service/service.content';
import toast from '../../../utils/toast'
import UploadAccordion from '../components/accordion/UploadAccordion';
import EmptyPage from '../components/emptyPage/EmptyPage'

const UploadComp = (props) => {
    const navigate = useNavigate();
    const { addFile, filesUploading, getFileDetails, resetFileUploading } = useContext(UploaderContext);
    const [saving, setSaving] = useState(false);

    const handleFileUpload = (files) => {
        for (let index = 0; index < files.length; index++) {
            addFile(files[index], props.modal ? props.id : null);
        }
    };

    useEffect(() => {
        if (props.modal && Object.values(filesUploading).length > 0) {
            Object.values(filesUploading).map((file, index) => {
                if (!(file.progress < 100 && !file.preview) && props.uploadedFiles !== undefined && !props.uploadedFiles.includes(file.request_id)) {
                    props.uploadedFiles.push(file.request_id)
                    props.setUploadedFiles([...props.uploadedFiles]);
                }
            });

            if (props.uploadedFiles && Object.values(filesUploading).length === props.uploadedFiles.length) {
                setTimeout(() => {
                    props.toggle();
                }, 2000);
            }
        }
    }, [filesUploading])

    const hanldeDetailsSubmit = () => {
        setSaving(true);
        const fileDetails = getFileDetails();
        submitContent({ files: fileDetails })
            .then((res) => {
                resetFileUploading()
                toast.success("Content has been uploaded!");
                navigate("/stock/content-manager")
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false));
    };

    return (
        <div>
            <PageHeading
                heading={"Uploading Queue"}
                profile
            />
            <div className='px-[30px] mt-5'>
                <UploadInput handleFileUpload={handleFileUpload} />
            </div>
            {!props.modal &&
                (Object.values(filesUploading).length > 0 ? (
                    <>
                        <UploadCard saving={saving} submit={hanldeDetailsSubmit} currentUploading={Object.values(filesUploading)} />
                    </>
                ) : (
                    <EmptyPage />
                ))}
        </div>
    )
}

export default function Upload(props) {
    return (
        <UploadComp {...props} />
    );
}
