import React from "react";
import { IoEye, IoPencil, IoShareSocialOutline } from "react-icons/io5";
import Thumb from "../../../../assets/images/creator.jpeg"
import { FiDownload } from "react-icons/fi";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { FaBoxArchive } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";

const FileGridView = ({ hide = false, grid = 2, data, archiveFile, deleteFile, restoreFile, downloadFile, openViewContent }) => {
    return (
        <div className={`grid grid-cols-${grid} lg:grid-cols-${grid + 1} gap-6`}>
            {data.map((item, index) => {
                return <div key={index} className="bg-theme-5 p-2 rounded-md">
                    <div className={"relative overflow-hidden"}>
                        <img
                            onError={(e) => {
                                e.target.src = Thumb;
                            }}
                            src={item?.meta?.image || Thumb} alt={"thumb"}
                            className={"w-full h-[250px] rounded-[5px] object-cover"} />
                        <div
                            onClick={(ev) => {
                                ev.stopPropagation()
                                openViewContent(item.id)
                            }}
                            className="absolute opacity-0 bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black/50
                                transition duration-300 ease-in-out hover:opacity-100 flex flex-col cursor-pointer">
                            <div className={"flex flex-col p-2 gap-2 justify-center items-end"}>
                                {/* <span className={"w-8 h-8 flex justify-center cursor-pointer" +
                                    " items-center text-white text-[16px] bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                    }}
                                >
                                    <FaEdit />
                                </span> */}
                                <span className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                                    " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        openViewContent(item.id)
                                    }}
                                >
                                    <IoEye />
                                </span>
                                <span
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        downloadFile(item.id)
                                    }}
                                    className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                                        " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                                    <FiDownload />
                                </span>
                                {hide ? null :
                                    <>
                                        {item.meta?.deleted_at ?
                                            <span
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    restoreFile(item.id)
                                                }}
                                                className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                                                    " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                                                <MdOutlineSettingsBackupRestore />
                                            </span>
                                            :
                                            <span
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    archiveFile(item.id)
                                                }}
                                                className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px]" +
                                                    " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                                                <FaBoxArchive />
                                            </span>
                                        }
                                        <span
                                            onClick={(ev) => {
                                                ev.stopPropagation()
                                                deleteFile(item.id);
                                            }}
                                            className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                                                " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                                            <RiDeleteBin5Fill />
                                        </span>
                                    </>}

                            </div>
                        </div>
                    </div>
                    <div className={"pt-2 flex flex-col gap-1"}>
                        <span title={item.meta?.name} className={"text-white text-[14px] font-bold truncate pr-5"}>
                            {item.meta?.name}
                        </span>
                        <div className={"flex flex-wrap gap-1"}>
                            {item.tags?.length && item.tags.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className={"text-gray-400 text-[13px] font-medium h-4 flex items-center"}
                                    >
                                        #{item}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </div>
            })}

        </div>
    )
}

export default FileGridView;
