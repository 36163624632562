import React, { useState } from "react";
import MenuDrawer from "./menuDrawer";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { NavLink, useLocation } from "react-router-dom";
import MenuDropdown from "../sidebar/components/menuDropdown";
import { RxDashboard, RxGear } from "react-icons/rx";
import { IoCloudOutline } from "react-icons/io5";
import { AiOutlineStock } from "react-icons/ai";

const sidebarMenu = [
    {
        name: "Dashboard",
        path: "/",
        icon: <RxDashboard />,
        active: true
    },
    {
        name: "CreatorCloud",
        path: "/creators",
        icon: <IoCloudOutline />,
        active: false,
        subMenu: [
            {
                name: "My Drive",
                path: "/cloud/drive",
            },
            {
                name: "Upload Queue",
                path: "/cloud/upload",
            },
            {
                name: "Shared with me",
                path: "/cloud/share",
            },
            {
                name: "Earnings",
                path: "/cloud/earnings",
            },
            {
                name: "Watermark",
                path: "/cloud/watermark",
            },
            {
                name: "Contracts (Soon)",
                path: "/cloud/contracts",
            },
            {
                name: "Upgrade",
                path: "/cloud/upgrade",
            },
        ]
    },
    {
        name: "CreatorStock",
        icon: <AiOutlineStock />,
        active: false,
        subMenu: [
            {
                name: "My Content",
                path: "/stock/content-manager",
            },
            {
                name: "Upload Queue",
                path: "/stock/upload"
            },
            {
                name: "Purchased",
                path: "/stock/purchase",
            },
            {
                name: "Earnings",
                path: "/stock/earnings",
            },
        ]
    },
    {
        name: "Storage",
        icon: <RxGear />,
        active: false,
    },
    {
        name: "Settings",
        icon: <RxGear />,
        active: false,
        subMenu: [
            {
                name: "Profile",
                path: "/settings/profile",
            },
            {
                name: "Change Password",
                path: "/settings/change-password"
            },
            {
                name: "Logout",
                path: "/",
            },
        ]
    },
]
const DrawerContainer = ({ }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    return (
        <>
            <MenuDrawer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Menu"
                description="Try something new!"
            >

                <div className="min-h-[70px] h-[70px] flex justify-start items-center gap-2 px-4">
                    <NavLink to="/" className={"text-white font-bold text-[20px]  flex flex-row gap-2"}>
                        <img className={"w-10"}
                            src={"https://creatorstock.io/static/media/creatorstock.1f4ebc4c1d8192f70734bdbc203fefce.svg"}
                            alt={"svg"} />
                        CreatorCloud
                    </NavLink>
                </div>

                <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                    <nav className="mt-2 py-4 px-4">
                        <div>
                            <ul className="mb-6 flex flex-col gap-2">
                                {sidebarMenu.map((item, index) => {
                                    // console.log(location, "Locartion")
                                    return (
                                        item.subMenu ?
                                            <MenuDropdown
                                                name={item.name}
                                                icon={item.icon}
                                                subMenu={item.subMenu}
                                                path={item.path}
                                                location={location}
                                                active={item.active}

                                            />
                                            :
                                            <li
                                                key={index}
                                                className={
                                                    `${item.active === true && "bg-black"},
                                             ${location.pathname === item.path ? 'bg-theme-2' : ''}
                                            rounded-[10px]`
                                                }
                                            >
                                                <NavLink
                                                    to={item.path}
                                                    className={
                                                        `group relative flex items-center gap-2 rounded-sm py-2 px-4 font-bold 
                                                 duration-300 ease-in-out text-[14px] text-white h-12
                                                 ${location.pathname === item.path ? 'text-theme-peach-100' : ''}`
                                                    }
                                                >
                                                    {item.icon}
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </nav>
                </div>


            </MenuDrawer>
            <button onClick={() => setIsOpen(!isOpen)} className={"w-10 h-10 bg-theme-3 rounded-md flex" +
                " justify-center items-center"}>
                <HiOutlineMenuAlt2 className={"text-[20px]"} />
            </button>
        </>
    )
}
export default DrawerContainer;
