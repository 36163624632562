import React, { Fragment, useState } from 'react';
import { IoEye, IoPencil, IoShareSocialOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { FaBoxArchive } from "react-icons/fa6"
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import Thumb from '../../../../assets/images/no-thumb.jpg'


const DataCards = ({
    contents,
    deleteFile,
    editFile,
    archiveFile,
    restoreFile,
    downloadFile,
    viewContent
}) => {
    return (
        <Fragment>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
                {contents?.map((item, index) => {
                    return (
                        <div key={index} className={"group bg-theme-5 p-2 rounded-md"}>
                            <div className={"relative overflow-hidden"}>
                                <div
                                    className="absolute border-0 bg-transparent w-[100px] left-[5px] top-[5px]">
                                    {item.meta?.approved === 1 ? (
                                        <span
                                            className="border-2 border-white rounded-sm bg-white px-1 text-[14px]"
                                            style={{ color: "#22a714" }}
                                        >
                                            Approved
                                        </span>
                                    ) : item.meta.approved === 2 ? (
                                        <span
                                            className="border-2 border-white rounded-sm bg-white px-1 text-[14px]"
                                            style={{ color: "#fa3e3e" }}
                                        >
                                            Rejected
                                        </span>
                                    ) : (
                                        <span
                                            className="border-2 border-white rounded-sm bg-white px-1 text-[14px]"
                                            style={{ color: "#fc723b" }}
                                        >
                                            Pending
                                        </span>
                                    )}
                                </div>
                                <img
                                    src={item?.meta?.image || Thumb}
                                    onError={(e) => {
                                        e.target.src = Thumb;
                                    }}
                                    alt={"thumb"}
                                    className={"w-full h-[250px] rounded-[5px] object-cover"}
                                />
                                <OverlayButtons
                                    item={item}
                                    onClickCard={(ev) => {
                                        ev.stopPropagation()
                                        viewContent(item.id)
                                    }}
                                    onClickView={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        viewContent(item.id)
                                    }}
                                    onClickDownload={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        downloadFile(item.id)
                                    }}
                                    onClickEdit={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        editFile(item.id)
                                    }}
                                    onClickRestore={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        restoreFile(item.id)
                                    }}
                                    onClickArchive={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        archiveFile(item.id);
                                    }}
                                    onClickDelete={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation()
                                        deleteFile(item.id);
                                    }}
                                />

                            </div>

                            <div className={"pt-2 flex flex-col gap-1"}>
                                <span className={"text-white text-[14px] font-bold"}>
                                    {item?.meta?.name}
                                </span>
                                <div className={"flex flex-wrap gap-1"}>
                                    <span className={"text-gray-400 text-[13px] font-medium h-4 flex items-center"}>
                                        {item.meta?.date} |
                                    </span>
                                    <span className={"text-gray-400 text-[13px] font-medium h-4 flex items-center"}>
                                        {item.meta?.time} |
                                    </span>
                                    <span className={"text-gray-400 text-[13px] font-medium h-4 flex items-center"}>
                                        {item.meta?.size}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Fragment>
    );
};

const OverlayButtons = ({ item, onClickShare, onClickCard, onClickView, onClickDownload, onClickEdit, onClickRestore, onClickArchive, onClickDelete }) => {
    return (
        <div onClick={onClickCard} className="absolute h-full w-full transition duration-300 ease-in-out hidden group-hover:flex flex-col
         left-0 right-0 top-0 bottom-0 bg-black/20 cursor-pointer">
            <div className={"flex flex-col p-2 gap-2 justify-center items-end"}>
                <span className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                    " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}
                    onClick={onClickView}
                >
                    <IoEye />
                </span>
                <span
                    onClick={onClickDownload}
                    className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                        " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                    <FiDownload />
                </span>
                <span
                    onClick={onClickEdit}
                    className={"w-8 h-8 flex justify-center cursor-pointer" +
                        " items-center text-white text-[16px] bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                    <FaEdit />
                </span>
                {item.meta?.deleted_at ?
                    <span
                        onClick={onClickRestore}
                        className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                            " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                        <MdOutlineSettingsBackupRestore />
                    </span>
                    :
                    <span
                        onClick={onClickArchive}
                        className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px]" +
                            " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                        <FaBoxArchive />
                    </span>
                }
                <span
                    onClick={onClickDelete}
                    className={"w-8 h-8 flex justify-center cursor-pointer items-center text-white text-[16px] " +
                        " bg-black/80 hover:bg-theme-peach-100 rounded-[4px]"}>
                    <RiDeleteBin5Fill />
                </span>
            </div>
        </div>
    )
}

export default DataCards;
