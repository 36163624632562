import { IoMdCheckmark } from "react-icons/io";

const PlanCard = ({ plan, style, buttontxt, theme = false }) => {
    return (
        <div
            className={style}>
            <div className={"flex flex-col items-center border-b-2 border-[#3a3a3a96] pb-4 mb-4"}>
                <span className={"text-[20px] font-bold"}>{plan.title}</span>
                <span className={"text-[28px]"}>{plan.price}</span>
                <span className={"text-[18px] text-[#8f8c8c96] h-10 flex items-center font-bold"}>{plan.uses}</span>
            </div>
            <div className="px-5">
                <div className={"flex flex-col gap-4 min-h-[350px]"}>
                    {plan.heading && <div className="mb-2 text-[20px]">{plan?.heading}</div>}
                    {plan.list.map((item, index) => {
                        return (
                            <div key={index} className={"text-[16px] flex gap-2 items-center"}>
                                <IoMdCheckmark className={"text-[20px] text-theme-peach-100"} />
                                {item}
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            <div>
                {theme ?
                    <button className={"w-full text-[22px] text-black h-[60px] flex justify-center items-center bg-theme-peach-100 hover:bg-theme-peach-200 rounded-md " +
                        " font-bold"}>
                        {buttontxt}
                    </button> :
                    <button className={"w-full text-[22px] text-white h-[60px] flex justify-center items-center border border-[#3a3a3a96] hover:bg-white  hover:text-black rounded-md " +
                        " font-bold"}>
                        {buttontxt}
                    </button>}
            </div>
        </div>
    )
}
export default PlanCard;
