import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RxDashboard, RxGear } from "react-icons/rx";
import { IoCloudOutline } from 'react-icons/io5';
import MenuDropdown from "./components/menuDropdown";
import { UploaderContext } from '../../../context/uploader.context';
import { MdOutlineCameraAlt } from 'react-icons/md';


const sidebarMenu = [
    {
        name: "Dashboard",
        path: "/",
        icon: <RxDashboard />,
        active: true
    },
    {
        name: "CreatorCloud",
        path: "/creators",
        icon: <IoCloudOutline />,
        active: false,
        subMenu: [
            {
                name: "My Drive",
                path: "/cloud/drive",
            },
            {
                name: "Shared with me",
                path: "/cloud/share",
            },
            {
                name: "Earnings",
                path: "/cloud/earnings",
            },
            {
                name: "Contracts (Soon)",
                path: "/cloud/contracts",
            },
            {
                name: "Upgrade",
                path: "/cloud/upgrade",
            },
        ]
    },
    {
        name: "CreatorStock",
        icon: <MdOutlineCameraAlt  />,
        active: false,
        subMenu: [
            {
                name: "My Content",
                path: "/stock/content-manager",
            },
            {
                name: "Upload Queue",
                path: "/stock/upload"
            },
            {
                name: "Purchased",
                path: "/stock/purchase",
            },
            {
                name: "Earnings",
                path: "/stock/earnings",
            },
        ]
    },
    {
        name: "Settings",
        icon: <RxGear />,
        active: false,
        subMenu: [
            {
                name: "Profile",
                path: "/settings/profile",
            },
            {
                name: "Change Password",
                path: "/settings/change-password"
            },
            {
                name: "Logout",
                path: "/",
            },
        ]
    },
]
const Sidebar = () => {
    const location = useLocation();
    const { storageData } = useContext(UploaderContext);

    return (
        <aside
            className={`absolute left-0 top-0 z-9999 hidden lg:flex h-screen w-[260px] flex-col overflow-y-hidden bg-theme-1
             duration-300 ease-linear lg:static lg:translate-x-0`}
        >
            <div className="min-h-[70px] h-[70px] flex justify-center items-center gap-2">
                <NavLink to="/" className={"text-white font-bold text-[20px]  flex flex-row gap-2"}>
                    <img className={"w-10"} src={"https://creatorstock.io/static/media/creatorstock.1f4ebc4c1d8192f70734bdbc203fefce.svg"} alt={"svg"} />
                    CreatorCloud
                </NavLink>
            </div>

            <div className="no-scrollbar flex flex-col duration-300 ease-linear">
                <nav className="mt-5 lg:mt-5">
                    <div className={"flex flex-col"}>
                        <ul className="mb-6 flex flex-col gap-2 h-[calc(100vh-150px)] overflow-y-auto py-4 px-4">
                            {sidebarMenu.map((item, index) => {
                                console.log(location, "Locartion")
                                return (
                                    item.subMenu ?
                                        <MenuDropdown
                                            name={item.name}
                                            icon={item.icon}
                                            subMenu={item.subMenu}
                                            path={item.path}
                                            location={location}
                                            active={item.active}

                                        />
                                        :
                                        <li
                                            key={index}
                                            className={
                                                `${item.active === true && "bg-black"},
                                             ${location.pathname === item.path ? 'bg-theme-2' : ''}
                                            rounded-[10px]`
                                            }
                                        >
                                            <NavLink
                                                to={item.path}
                                                className={
                                                    `group relative flex items-center gap-2 rounded-sm py-2 px-4 font-bold 
                                                 duration-300 ease-in-out text-[14px] text-white h-12
                                                 ${location.pathname === item.path ? 'text-theme-peach-100' : ''}`
                                                }
                                            >
                                                {item.icon}
                                                {item.name}
                                            </NavLink>
                                        </li>
                                )
                            })}
                        </ul>
                        <div className={"flex flex-0 text-white justify-center items-center border-t border-black/70"}>
                            <div class="h-10 flex items-center text-[12px] rounded font-normal leading-[14px] px-4 bg-theme-3 ">
                                {storageData?.formatted || 0} used</div>
                        </div>
                    </div>
                </nav>
            </div>

        </aside>
    );
};

export default Sidebar;
