import React from "react";
import TableDropdown from "../../components/table/components/tableDropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import Thumb from "../../../../assets/images/no-thumb.jpg"

const FileListView = ({ hide = false, data, openViewContent, archiveFile, deleteFile, restoreFile, downloadFile }) => {

    const links = [
        { href: '/', label: 'View', onClick: openViewContent },
        { href: '/', label: 'Download', onClick: downloadFile },
        hide ? null : { href: '/', label: 'Delete', onClick: deleteFile },
        hide ? null : { href: '/', label: 'Archive', onClick: archiveFile },
    ].filter(Boolean);

    return (
        <div className="">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-[14px]"}>
                        <th className="border-0 p-4 font-medium">File</th>
                        <th className="border-0 p-4 font-medium">Name</th>
                        <th className="border-0 p-4 font-medium">Tags</th>
                        <th className="border-0 p-4 font-medium">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        return <tr key={item?.id} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4">
                                <div className={"flex flex-row items-center gap-2 w-12 h-12"}>
                                    <img
                                        onError={(e) => {
                                            e.target.src = Thumb;
                                        }}
                                        className={"w-full h-full object-cover rounded-md"} src={item.meta?.image || Thumb}
                                        alt={"thumb"} />
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2 truncate">{item.meta?.name}</td>
                            <td className="border-0 px-4 py-2">
                                <div className={"flex gap-2"}>
                                    {item.tags?.length ? item.tags?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className={"flex items-center bg-theme-3 px-2 h-6 rounded-sm text-[12px]"}>
                                                {item}
                                            </div>
                                        )
                                    }) : <div>N/A</div>}
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2">
                                <TableDropdown data={item} links={links} />
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default FileListView;
