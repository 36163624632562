const DefaultInput = ({
    required,
    label,
    type,
    position = false,
    placeholder,
    bg,
    icon,
    name,
    value,
    className,
    ref = null,
    error = null,
    disabled=false,
    onChange = () => null
}) => {

    const handleChange = (value) => {
        onChange(name, value)
    }
    return (
        <div className={"relative"}>
            {label && <label className={"text-white text-[14px] font-medium"}>{label}</label>}
            {icon &&
                <div className={`absolute h-10 bg-black/20 bottom-0 ${position ? "right-0" : "left-0"} w-10 rounded-[2px]
                 justify-center items-center flex text-[20px] text-white dark:text-black`}>
                    {icon}
                </div>
            }
            <input
                disabled={disabled}
                ref={ref}
                className={`w-full h-10 mt-1 rounded-[5px] focus:outline-none ${className ? className : "text-white"}  text-[14px]
                ${bg ? bg : "bg-white "} ${icon ? "pl-12 pr-2" : "pl-2 pr-2"} ${error && !value ? "border border-[red]" : ""}`}
                type={type}
                name={name}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                required
            />
        </div>
    )
}
export default DefaultInput;
