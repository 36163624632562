const TextareaInput = ({ label, show = false, type, placeholder, bg, name, onChange, value }) => {
    const handleTextareaChange = (event) => {
        onChange(name, event.target?.value)
    };
    return (
        <div className={!show && "float-left"}>
            {label && <label>{label}</label>}
            <textarea
                id="message" rows="4"
                value={value}
                onChange={handleTextareaChange}
                className={`w-full min-h-[60px] ${bg ? bg : "bg-white "} rounded-[5px] p-2 text-[14px] text-white focus:outline-none`}
                placeholder={placeholder}>
            </textarea>

        </div>
    )
}
export default TextareaInput;
