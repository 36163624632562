import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { GiCoins } from "react-icons/gi";

const EarningCard = ({ walletBalance }) => {
    return (
        <div className={"bg-theme-2 p-4 grid grid-cols-2 gap-5"}>
            <div className={"bg-theme-3 text-white flex flex-row justify-start items-start p-4 gap-4"}>
                <MdOutlineAccountBalanceWallet className={"text-[40px]"} />
                <div className={"text-white flex flex-col"}>
                    <span className={"text-[30px]"}>Balance</span>
                    <span className={"text-[20px]"}>
                        £{parseFloat(walletBalance?.totalBalance).toFixed(2)}</span>
                </div>
            </div>
            <div className={"bg-theme-3 text-white flex flex-row justify-start items-start p-4 gap-4"}>
                <GiCoins className={"text-[40px]"} />
                <div className={"text-white flex flex-col"}>
                    <span className={"text-[30px]"}>Lifetime earnings</span>
                    <span className={"text-[20px]"}>£{parseFloat(walletBalance?.walletLifetimeBalance).toFixed(2)}</span>
                </div>
            </div>
        </div>
    )
}
export default EarningCard;
