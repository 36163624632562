import React, { useContext } from 'react';
import Thumb from "../../../../assets/images/creator.jpeg"
import Thumb2 from "../../../../assets/images/content2.jpg"
import DefaultInput from "../../components/inputs/DefaultInput";
import TextareaInput from "../../components/inputs/TextareaInput";
import SwitchInput from "../../components/inputs/SwitchInput";
import UploadHeader from './UploadHeader';
import { UploaderContext } from '../../../../context/uploader.context';
import { MdDelete } from "react-icons/md";
import TagInput from '../../components/inputs/TagInput';
import Button from '../../components/buttons/Button';

const UploadCard = ({ submit, currentUploading, saving }) => {
    return (
        <div>
            {currentUploading?.length > 0 && <UploadHeader files={currentUploading} />}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 px-[30px]">
                {currentUploading.length > 0 &&
                    currentUploading?.map((file, index) => (
                        <FileCard file={file} index={index} />
                    ))}
            </div>

            <div className="flex justify-center items-center my-5">
                <Button name={"Submit Now"} onClick={submit}/>
            </div>
        </div>
    );
};

export default UploadCard;


const FileCard = (props) => {
    const { updateFileDetails, removeFile } = useContext(UploaderContext);

    const handleUpdate = (key, value) => {
        updateFileDetails(props.file.requestId, key, value);
    };

    return (
        <div key={props.index} className={"bg-theme-3 rounded-[10px]"}>
            {props.file.error && <div className="fileError">{props.file.error}</div>}
            <div className={"relative"}>
                {props.file.preview ?
                    <img src={props.file.preview} alt={"thumb"} className={"w-full h-[200px] rounded-t-[10px] object-cover"} /> : <div className='w-full h-[200px] rounded-t-[10px]'></div>}
                <div className="absolute bottom-0 left-0 right-0 top-0 rounded-t-[10px] h-full w-full overflow-hidden bg-black/50
                            transition duration-300 ease-in-out hover:opacity-100 flex items-end pb-4 px-2">
                    {props.file.progress < 100 && !props.file.preview ?
                        <div className={"flex flex-col w-full gap-1"}>
                            <span className={"text-[12px] text-white"}>{`Uploading... ${props.file.progress}%`}</span>
                            <div className={"w-full h-[5px] bg-white/10 rounded-full overflow-hidden"}>
                                <div style={{ width: `${props?.file?.progress}%` }} className={`h-full bg-white`} />
                            </div>
                        </div> : null}
                    {(props.file.error || props.file.preview) ?
                        <div className='w-full px-2 flex justify-end' onClick={() => removeFile(props.file.requestId)}>
                            <MdDelete size={20} className='text-white text-end cursor-pointer' />
                        </div> : null}
                </div>
            </div>

            <div className={"p-2 flex flex-col gap-2"}>
                <DefaultInput
                    placeholder={"Enter Caption"}
                    type={"text"}
                    bg="bg-neutral"
                    name="caption"
                    onChange={handleUpdate}
                />
                <TextareaInput
                    placeholder={"Description"}
                    type={"text"}
                    bg="bg-neutral"
                    name="description"
                    onChange={handleUpdate}
                />
                <SwitchInput
                    label={props.file.is_paid ? "Premium" : "Free"}
                    bg="bg-neutral"
                    name="is_paid"
                    checked={props.file.is_paid}
                    onChange={handleUpdate}
                />
                <div className='tag-list mb-3'>
                    <TagInput tags={props.file.tags} updateTags={(val) => handleUpdate("tags", val)} />
                </div>
            </div>
        </div>
    );
};

